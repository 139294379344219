import React, { useEffect } from 'react';
// import Lottie from 'lottie-web';
import Lottie from 'lottie-react';

import { useInView } from 'react-intersection-observer';


//lotties
import awwwards from "../../../assets/Awwwards - Logo.json";
import cssda from "../../../assets/CSSDA - Logo.json";
import upword from "../../../assets/Upwork - Logo.json";



const HomeAboutUsSection = (props) => {


    const [ref, inView] = useInView({
        threshold: 0, // Trigger animation when the div is 50% visible
        triggerOnce: true,
        delay: 1.5
    });
    return (
        <section className="about-us-section light-bg mt-125">
        {/* <section className="about-us-section" style={{backgroundImage : 'url('+BgPattern+')'}} ref={sectionRef}> */}
            <div className="container">
                <div className='about-detail'>
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="title-block">
                                {/* <SlideUp duration={400} delay={500}> */}
                                    <h2 className="h1 section-title normal-spacing about-heading-reveal">{props.mainTitle}</h2>
                                {/* </SlideUp> */}
                                {/* <p><Link to={props.linkURL}>{props.linkText}</Link></p> */}
                                {/* <div className="count-items">
                                    {props.list?.map((object, i) => {
                                        return (
                                            <div className="item" key={shortid.generate()}>
                                                <h2 className="h1 value">{parse(object.value)}</h2>
                                                <div className="label"><p className="small">{parse(object.label)}</p></div>
                                            </div>
                                        )
                                    })}
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-1"></div>
                        <div className="col-xl-14">
                            <p className="section-description about-des-reveal">{props.mainDescription}</p>
                                <div className="awards-list">
                                    {/* {props.awardsList?.map((object, i) => {
                                        return (
                                            <div className="item" key={shortid.generate()}>
                                                <img
                                                    src={object.image?.sourceUrl}
                                                    width={object.image?.width}
                                                    alt={object.image?.altText}
                                                />
                                            </div>
                                        )
                                    })} */}
                                    <div className="item" ref={ref}>
                                        {inView && (
                                            <Lottie 
                                            animationData={awwwards}
                                            loop={false}
                                            height={200}
                                        />
                                        )}
                                    </div>
                                    <div className="item">
                                        {inView && (
                                            <Lottie 
                                            animationData={cssda}
                                            loop={false}
                                            height={200}
                                        />
                                        )}
                                    </div>
                                    <div className="item">
                                        {inView && (
                                            <Lottie 
                                            animationData={upword}
                                            loop={false}
                                            height={200}
                                        />
                                        )}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="about-gallary">
                    <div className="row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-22">
                            {/* <ScaleUp duration={300} delay={500}> */}
                                <div className="gallary">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="image-block-1 mb">
                                                <img
                                                    src={props.image11x?.sourceUrl}
                                                    srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                                                    width={props.image11x?.width}
                                                    alt={props.image11x?.altText}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-13">
                                            <div className="image-block-2 mb" >
                                                <img
                                                    src={props.image21x?.sourceUrl}
                                                    srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                                                    width={props.image21x?.width}
                                                    alt={props.image21x?.altText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-lg-13">
                                            <div className="image-block-3 mb">
                                                <img
                                                    src={props.image31x?.sourceUrl}
                                                    srcSet={props.image32x?.sourceUrl + " 2x, " + props.image31x?.sourceUrl + " 1x"}
                                                    width={props.image31x?.width}
                                                    alt={props.image31x?.altText}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-10">
                                            <div className="image-block-4">
                                                <img
                                                    src={props.image41x?.sourceUrl}
                                                    srcSet={props.image42x?.sourceUrl + " 2x, " + props.image41x?.sourceUrl + " 1x"}
                                                    width={props.image41x?.width}
                                                    alt={props.image41x?.altText}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            {/* </ScaleUp> */}
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                </div>
            </div>
            {/* <div className="slider-block">
                <AboutImageSlider
                    imageSlider={props.imageSlider}
                />
            </div> */}
            {/* <div className='container'>
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-20">
                        <div className="awards-section">
                            <h2 className="h1 section-title normal-spacing">{props.awardMainTitle}</h2>
                            <p className="section-description">{props.awardMainDescription}</p>
                            <div className="award-items">
                                <div className="row">
                                    {props.awardsList?.map((object, i) => {
                                        return (
                                            <div className="col-lg-6 col-sm-12" key={shortid.generate()}>
                                                <div className="item">
                                                    <div className="thumbnail">
                                                        <img
                                                            src={object.awardImage?.sourceUrl}
                                                            width={object.awardImage?.width}
                                                            alt={object.awardImage?.altText}
                                                        />
                                                    </div>
                                                    <p className="small">{parse(object.awardName)}</p>
                                                    <p className="extra-small">{parse(object.awardBy)}</p>
                                                </div>
                                            </div>    
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div> */}
        </section>
    )
}

export default HomeAboutUsSection